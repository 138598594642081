import { Form, Input } from 'antd';

const AttributionWindow = ({ env, disabled, form }) => {
  const attributionWindowFieldName = [env, 'am_settings', 'attribution_window_days'];

  return (
    <>
      <Form.Item
        label="Attribution Window In Days"
        tooltip="The Attribution Window determines the period during which conversions are credited. The value should fall within the 396 days range. If the value is not set the Attribution will expire in 365 days by default."
        name={attributionWindowFieldName}
        getValueProps={(value) => {
          // Transform '0' into '' to show the placeholder
          return { value: value === '0' ? '' : value };
        }}
        getValueFromEvent={(event) => {
          const value = event.target.value;
          // When cleared, return '0' as the form value
          return value === '' ? '0' : value;
        }}
      >
        <Input type="number" min={0} max={396} placeholder={365} disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default AttributionWindow;
