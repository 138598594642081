import React from 'react';
import { Alert, Card, Col, Row, Table, Typography } from 'antd';
import moment from 'moment';
import { MetricsCard, MetricsTooltips } from '../../components';
import styles from '../../components/Metrics/Metrics.module.less';
import { formatMathRoundWithoutDecimals, formatWithThousandCommas } from '../../services/utils';
import MapChart from './MapChart';
import { RLMetricsPie } from './RLMetricsPie';

const generateCsvName = (name, dateStartString, dateEndString) =>
  `${name} (${moment.utc(dateStartString).format('YYYY-MM-DD')} - ${moment
    .utc(dateEndString)
    .format('YYYY-MM-DD')}).csv`; // removes "T00:00:00Z" from end of date string timestsamps

const RLMetricsView = ({
  org,
  dateRange,
  mwMetrics,
  abMetrics,
  performanceMetrics,
  geoPerformanceMetrics,
}) => {
  const [dateStartString, dateEndString] = dateRange;

  const { name: orgName, id: orgId } = org;
  const { data: mwrMetrics, error: mwrMetricsError, loading: mwMetricsLoading } = mwMetrics;
  const { data: adblockerMetrics, error: adBlockUsersError, loading: abMetricsLoading } = abMetrics;
  const {
    data: performanceData,
    error: performanceError,
    loading: performanceLoading,
  } = performanceMetrics;
  const {
    data: geoData,
    error: geoPerformanceError,
    loading: geoPerformanceLoading,
  } = geoPerformanceMetrics;

  const geoPerformanceData = geoData?.metrics || [];

  const recoveryData = (metrics) => {
    const baseMetrics = [
      { category: 'Single Click Allowlisting', count: metrics?.sca_recoveries || 0 },
      { category: 'User Manual Allowlisting', count: metrics?.ab_recoveries || 0 },
    ];

    if (metrics?.aa_recoveries && metrics.aa_recoveries > 0) {
      return [...baseMetrics, { category: 'Acceptable Ads Allowed', count: metrics.aa_recoveries }];
    }
    return baseMetrics;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Recovered Revenue"
                    value={
                      mwrMetrics?.recovered_revenue ? Math.round(mwrMetrics.recovered_revenue) : 0
                    }
                    valueProps={{ prefix: '$', precision: 0 }}
                    loading={mwMetricsLoading}
                    error={mwrMetricsError}
                    help="The total Readership Link recovered revenue generated from monetizing AdBlock (Acceptable Ads) and non-AdBlock users throughout the date range specified."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6} xl={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Renders"
                    value={mwrMetrics?.renders || 0}
                    error={mwrMetricsError}
                    loading={mwMetricsLoading}
                    help="How many times Readership Link was rendered on a user session throughout the date range specified."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Users Recovered"
                    value={mwrMetrics?.recoveries || 0}
                    error={mwrMetricsError}
                    loading={mwMetricsLoading}
                    help="How many users disabled their adblocker throughout the date range specified."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Recovery Rate"
                    value={(mwrMetrics?.recovery_rate && mwrMetrics?.recovery_rate * 100) || 0}
                    valueProps={{ suffix: '%', precision: 1 }}
                    error={mwrMetricsError}
                    loading={mwMetricsLoading}
                    help="Percentage of the renders led to users enabled AA or disabled Ad Blocker throughout the date range specified."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Subscription Redirect Rate"
                    value={
                      (mwrMetrics?.primary_cta_redirect_rate &&
                        mwrMetrics?.primary_cta_redirect_rate * 100) ||
                      0
                    }
                    loading={mwMetricsLoading}
                    valueProps={{ suffix: '%', precision: 1 }}
                    error={mwrMetricsError}
                    help="Percentage of the renders that led to users clicks on subscription redirect button throughout the date range specified.
                                        The rate value may be zero if Readership Link is not set up to custom CTA mode."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Dismiss Rate"
                    value={(mwrMetrics?.dismiss_rate && mwrMetrics?.dismiss_rate * 100) || 0}
                    valueProps={{ suffix: '%', precision: 1 }}
                    error={mwrMetricsError}
                    loading={mwMetricsLoading}
                    help="Percentage of the renders that led the user to dismiss the message throughout the date range specified."
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MetricsCard
                    title="Bounce Rate"
                    value={
                      (mwrMetrics?.navigation_away_rate &&
                        mwrMetrics?.navigation_away_rate * 100) ||
                      0
                    }
                    loading={mwMetricsLoading}
                    valueProps={{ suffix: '%', precision: 1 }}
                    error={mwrMetricsError}
                    help="Percentage of the renders that led to the user abandoning the site during the date range specified."
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <br />

      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              <Card className={[styles.metricsCard, styles.dailyBreakdownCard]}>
                <Typography.Title level={5}>Performance Metrics</Typography.Title>
                <MetricsTooltips
                  id="tooltip_rl_performance"
                  isBackfillIncluded={false}
                  downloadCSV={
                    performanceData?.length !== 0
                      ? {
                          filename: generateCsvName(
                            `${orgName} - RL Performance Metrics`,
                            dateStartString,
                            dateEndString
                          ),
                          data: performanceData,
                        }
                      : null
                  }
                />
                {performanceError && <Alert message={performanceError} type="error" />}
                <Table
                  key={`daily-breakdown-${orgId}-${dateStartString}-${dateEndString}`} // Ensures the pagination is reset when the table data changes
                  className={styles.table}
                  size="small"
                  scroll={{ x: 'max-content' }}
                  loading={performanceLoading}
                  rowKey="date"
                  pagination={{
                    defaultPageSize: 4,
                    hideOnSinglePage: true,
                    pageSizeOptions: [4, 10, 20],
                  }}
                  dataSource={performanceData}
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      fixed: 'left',
                    },
                    {
                      title: 'AA Page Views',
                      dataIndex: 'aa_page_views',
                      render: formatWithThousandCommas,
                    },
                    {
                      title: 'AB Page Views',
                      dataIndex: 'ab_page_views',
                      render: formatWithThousandCommas,
                    },
                    {
                      title: 'AA Recoveries',
                      dataIndex: 'aa_recoveries',
                      render: formatWithThousandCommas,
                    },
                    {
                      title: 'AB Recoveries',
                      dataIndex: 'ab_recoveries',
                      render: formatWithThousandCommas,
                    },
                    {
                      title: 'Renders',
                      dataIndex: 'renders',
                      render: formatWithThousandCommas,
                    },
                    {
                      title: 'Revenue',
                      dataIndex: 'revenue',
                      render: formatMathRoundWithoutDecimals,
                    },
                  ]}
                />
              </Card>
            </Col>

            <Col xs={24} lg={12} xl={12}>
              <Card className={styles.metricsCard}>
                <RLMetricsPie
                  title="Traffic Breakdown by AdBlocker Type"
                  tooltipText="Number of times Readership Link rendered to Ad Blocker traffic, broken down by renders to users that has Acceptable Ads supported on the extension and those that did not."
                  metrics={adblockerMetrics}
                  metricsError={adBlockUsersError}
                  metricsLoading={abMetricsLoading}
                />
              </Card>
            </Col>
            <Col xs={24} lg={12} xl={12}>
              <Card className={styles.metricsCard}>
                <RLMetricsPie
                  title="Traffic Breakdown by Recovery Type"
                  tooltipText="Number of times user was recovered, broken down by recovery types"
                  metrics={recoveryData(mwrMetrics)}
                  metricsLoading={mwMetricsLoading}
                  metricsError={mwrMetricsError}
                />
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} xl={24}>
                  <Card>
                    <Typography.Title level={5}>Geo Performance heat map</Typography.Title>
                    <MapChart data={geoPerformanceData} />
                  </Card>
                </Col>
                <Col xs={24} xl={24}>
                  <Card className={[styles.metricsCard]}>
                    <Typography.Title level={5}>Geo Performance</Typography.Title>
                    <MetricsTooltips
                      id="tooltip_rl_geo_performance"
                      isBackfillIncluded={false}
                      downloadCSV={
                        geoPerformanceData.length !== 0
                          ? {
                              filename: generateCsvName(
                                `${orgName} - RL Geo Performance`,
                                dateStartString,
                                dateEndString
                              ),
                              data: geoPerformanceData,
                            }
                          : null
                      }
                    />
                    {geoPerformanceError && <Alert message={geoPerformanceError} type="error" />}
                    <Table
                      key={`daily-breakdown-${orgId}-${dateStartString}-${dateEndString}`} // Ensures the pagination is reset when the table data changes
                      className={styles.table}
                      size="small"
                      scroll={{ x: 'max-content' }}
                      loading={geoPerformanceLoading}
                      rowKey="id"
                      pagination={{
                        defaultPageSize: 4,
                        hideOnSinglePage: true,
                        pageSizeOptions: [4, 10, 20],
                      }}
                      dataSource={geoPerformanceData}
                      columns={[
                        {
                          title: 'Country',
                          dataIndex: 'id',
                          fixed: 'left',
                        },
                        {
                          title: 'AA PVs',
                          dataIndex: 'aa_page_views',
                          render: formatWithThousandCommas,
                        },
                        {
                          title: 'AB PVs',
                          dataIndex: 'ab_page_views',
                          render: formatWithThousandCommas,
                        },
                        {
                          title: 'Non AB PVs',
                          dataIndex: 'non_ab_page_views',
                          render: formatWithThousandCommas,
                        },
                        {
                          title: 'AA Recoveries',
                          dataIndex: 'aa_recoveries',
                          render: formatWithThousandCommas,
                        },
                        {
                          title: 'AB Recoveries',
                          dataIndex: 'ab_recoveries',
                          render: formatWithThousandCommas,
                        },
                        {
                          title: 'Renders',
                          dataIndex: 'renders',
                          render: formatWithThousandCommas,
                        },
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Alert
          message="Please note that dashboard figures are estimated and may differ slightly from actualized totals."
          type="info"
          style={{ width: '100%' }}
          showIcon
        />
      </Row>
    </>
  );
};

export default RLMetricsView;
