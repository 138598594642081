import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Radio,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import {
  CodeEditor,
  CodeEditorModal,
  HelpTooltip,
  OnceLoaded,
  SearchableEntityTableStatic,
  TagsSelector,
} from '../../components';
import api from '../../services/api';
import { TIME_ZONE } from '../../services/constants';
import {
  DeleteEntityPopconfirm,
  SaveEntityModal,
  ToggleEnablePopconfirm,
} from '../../services/handlers';
import { useAsync } from '../../services/hooks';
import {
  capitalize,
  compareAlphabetically,
  compareChronologically,
  createPrebidAdUnit,
  validateJson,
  validateSize,
} from '../../services/utils';
import styles from './StaticSpacesTable.module.less';
import { isNil } from 'lodash';

/**
 * Sorts sizes (each size in form: <width>x<height>) by `width` (and then `height` if widths are equal)
 */
function sortSizes(sizeA, sizeB) {
  return (
    parseInt(sizeA.split('x')[0], 10) - parseInt(sizeB.split('x')[0], 10) ||
    parseInt(sizeA.split('x')[1], 10) - parseInt(sizeB.split('x')[1], 10)
  );
}

/**
 * Prepares a space object for comparison by filtering out unnecessary keys
 * and sorting the associated bidders for consistency.
 */
function formatSpace(space, biddersById) {
  const normalizedSpace = { ...space };
  // Find bidders associated with the space and save their name and data
  normalizedSpace.bidders = (normalizedSpace.bidder_ids || [])
    .map((bidderId) => biddersById[bidderId])
    .filter((bidder) => bidder?.data) // Ignore 'adx' and 'direct' bidders whose data is `""`
    .sort((bidderA, bidderB) => {
      const nameComparison = compareAlphabetically(bidderA.name, bidderB.name);
      if (nameComparison === 0) {
        return compareAlphabetically(bidderA.data, bidderB.data);
      }

      return nameComparison;
    });

  normalizedSpace.sizes = [...normalizedSpace.sizes].sort(sortSizes);

  return createPrebidAdUnit(normalizedSpace);
}

function isSpaceEqual(spaces, otherScopeSpaces, biddersById, spaceName = undefined) {
  if (spaceName) {
    const space = spaces.find((s) => s.name === spaceName);
    const otherScopeSpace = otherScopeSpaces.find((s) => s.name === spaceName);

    if (otherScopeSpace) {
      const formatedSpace = formatSpace(space, biddersById);
      const formatedOtherScopeSpace = formatSpace(otherScopeSpace, biddersById);

      return JSON.stringify(formatedSpace) === JSON.stringify(formatedOtherScopeSpace);
    }

    return false;
  }

  if (spaces.length !== otherScopeSpaces.length) {
    return false;
  }
  const sortedSpaces = spaces
    .map((space) => formatSpace(space, biddersById))
    .sort((a, b) => compareAlphabetically(a.code, b.code));
  const sortedOtherScopeSpaces = otherScopeSpaces
    .map((space) => formatSpace(space, biddersById))
    .sort((a, b) => compareAlphabetically(a.code, b.code));

  return JSON.stringify(sortedOtherScopeSpaces) === JSON.stringify(sortedSpaces);
}

/**
 * Static Space-related components
 */
const options = [
  { value: 'all', label: 'all' },
  { value: 'desktop', label: 'desktop' },
  { value: 'mobile', label: 'mobile' },
];

const SpaceForm = ({ formInstance, saveError }) => (
  <Form
    name="space"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      name="name"
      label="Name"
      rules={[
        {
          required: true,
          whitespace: true, // Providing only whitespace is considered invalid
          message: 'Please input a name!',
        },
      ]}
      extra="This may be used by hooks to map the Space to googletag slots on the page; edit with caution!"
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="device_type"
      label="Device Type"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select options={options} />
    </Form.Item>
    <Form.Item
      name={['selector', 'value']}
      label="Selector"
      rules={[
        {
          required: true,
          whitespace: true, // Providing only whitespace is considered invalid
          message: 'Please input a selector!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="sizes"
      label="Sizes"
      rules={[
        {
          required: true,
          message: 'Please input at least 1 size!',
        },
      ]}
    >
      <TagsSelector addPrompt="Add Size" validateTag={validateSize} />
    </Form.Item>
    <Form.Item
      name="position"
      label="Position"
      tooltip={
        <div>
          OpenRTB `pos` value (representing where an ad unit is on the page) sent in Prebid bid
          requests to SSPs.
          <br />
          <br />
          0: Unknown
          <br />
          1: Above the Fold
          <br />
          2: [DEPRECATED] May or may not be initially visible depending on screen size/resolution.
          <br />
          3: Below the Fold
          <br />
          4: Header
          <br />
          5: Footer
          <br />
          6: Sidebar
          <br />
          7: Full Screen
        </div>
      }
      rules={[
        {
          validator: (_, value) => {
            if (value >= 0 && value <= 7 && value !== 2) {
              return Promise.resolve();
            } else if (value === 2) {
              return Promise.reject('Position 2 is deprecated!');
            } else {
              return Promise.reject('Please input a valid Position value!');
            }
          },
        },
      ]}
    >
      <InputNumber />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const CopySelectedSpaces = ({ selectedSpaces, clearSelection }) => (
  <Button
    icon={<CopyOutlined />}
    onClick={() => {
      let spacesJson;
      try {
        spacesJson = JSON.stringify(
          selectedSpaces.map((space) => createPrebidAdUnit(space)),
          null,
          2
        );
        copyToClipboard(spacesJson);
        clearSelection();
        message.success(
          `Space${selectedSpaces.length === 1 ? '' : 's'} successfully copied to clipboard!`
        );
      } catch (error) {
        message.error(`Copying to clipboard failed. Error: ${error}`);
      }
    }}
  >
    Copy
  </Button>
);

const CopySpace = ({ space }) => {
  if (!space) {
    return <LoadingOutlined spin />;
  } else {
    let parsingError;
    let spaceJson;
    try {
      spaceJson = JSON.stringify(createPrebidAdUnit(space), null, 2);
    } catch (error) {
      console.error(error);
      parsingError = error;
    }

    return parsingError ? null : (
      <Typography.Text
        copyable={{
          text: spaceJson,
        }}
      />
    );
  }
};

const EditSpace = ({ space, refetchSpaces }) => {
  return (
    <SaveEntityModal
      key="edit-space"
      triggerRender={({ openModal }) => (
        <Button type="link" size="small" onClick={openModal}>
          <EditOutlined />
        </Button>
      )}
      modalTitle="Edit Space"
      // Add space id to request, and use "name" value for "external_id" & "unit"
      // TODO: Convince back-end to change this API to no longer expose external_id/unit which we don't use and just create annoyance/confusion
      transformBeforeSave={(values) => ({
        id: space.id,
        external_id: values.name,
        unit: values.name,
        ...values,
      })}
      saveEntity={api.updateSpace}
      onSuccess={({ space }) => {
        // Show success message and trigger spaces refresh to reflect update
        message.success(`${space.name} successfully updated!`);
        refetchSpaces();
      }}
      formComponent={SpaceForm}
      formInitialValues={space}
    />
  );
};

const DeleteSelectedSpaces = ({ selectedSpaceIds, clearSelection, refetchSpaces }) => {
  const { execute: deleteSpaces } = useAsync(() => api.deleteSpaces(selectedSpaceIds));

  return (
    <DeleteEntityPopconfirm
      triggerRender={() => (
        <Button danger icon={<DeleteOutlined />}>
          Delete
        </Button>
      )}
      prompt={`Are you sure you want to delete ${
        selectedSpaceIds.length === 1 ? 'this 1 space' : `these ${selectedSpaceIds.length} spaces`
      }?`}
      deleteEntity={deleteSpaces}
      onDeleted={() => {
        // Clear selection, show success message, and trigger spaces refresh to reflect deletion
        clearSelection();
        message.success(`Space${selectedSpaceIds.length === 1 ? '' : 's'} successfully deleted!`);
        refetchSpaces();
      }}
    />
  );
};

const DeleteSpace = ({ spaceId, refetchSpaces }) => {
  const { execute: deleteSpace } = useAsync(() => api.deleteSpace(spaceId));

  return (
    <DeleteEntityPopconfirm
      prompt="Are you sure you want to delete this space?"
      deleteEntity={deleteSpace}
      onDeleted={() => {
        // Show success message and trigger spaces refresh to reflect deletion
        message.success('Space successfully deleted!');
        refetchSpaces();
      }}
    />
  );
};

/**
 * Static Space Bidder-related components
 */
const BidderForm = ({ formInstance, saveError }) => (
  <Form name="bidder" form={formInstance} preserve={false} requiredMark={false}>
    <Form.Item
      name="bidderParamsString"
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid JSON object for the bidder params!',
        },
      ]}
    >
      <CodeEditor placeholder="Bidder params JSON" height={200} />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const EditBidder = ({ bidder }) => {
  // `bidder.data` comes to us from the back-end as e.g. `"{\"bidder\":\"rubicon\",\"params\":{\"accountId\":\"11598\",\"floor\":0,\"siteId\":\"280758\",\"zoneId\":\"1401114\"}}"`
  const bidderData = JSON.parse(bidder.data);
  const bidderName = bidderData.bidder;
  const bidderParams = bidderData.params || {};
  const bidderParamsString = JSON.stringify(bidderParams, null, 2);

  return bidderName && bidderParamsString ? (
    <SaveEntityModal
      key="edit-bidder"
      triggerRender={({ openModal }) => (
        <Button type="link" size="small" onClick={openModal}>
          <EditOutlined />
        </Button>
      )}
      modalTitle="Edit Bidder Params"
      transformBeforeSave={(values) => ({
        // Add bidder id to request
        id: bidder.id,
        // Edit bidder's external_id to a unique value so that if a new Bidder is created for this space with the same data as the bidder before it was edited, there won't be any conflict
        // TODO: Convince back-end to change this API to no longer expose external_id which we don't use and just creates annoyance/confusion
        external_id: `${bidder.space_id}-${bidder.id}`,
        // Reconstruct `bidder.data` string with updated bidder params
        data: `{"bidder":"${bidderName}","params":${values.bidderParamsString}}`,
      })}
      saveEntity={api.updateBidder}
      onSuccess={({ bidder }) => {
        // Show success message and trigger bidders refresh to reflect update
        message.success(`Bidder successfully updated!`);
        mutate(['/BidderList', bidder.website_id]);
      }}
      formComponent={BidderForm}
      formInitialValues={{
        bidderParamsString,
      }}
    />
  ) : null;
};

const DeleteBidder = ({ bidder, refetchSpaces }) => {
  const { execute: deleteBidder } = useAsync(() => api.deleteBidder(bidder.id));

  return (
    <DeleteEntityPopconfirm
      prompt="Are you sure you want to delete this bidder?"
      deleteEntity={deleteBidder}
      onDeleted={() => {
        message.success('Bidder successfully deleted!');
        // Trigger spaces refresh to reflect Space's bidder deletion
        // (We don't need to mutate /BidderList because we only display bidders whose `id`s are found under a Space's `bidder_ids`)
        refetchSpaces();
      }}
    />
  );
};

const Bidders = ({ bidders, refetchSpaces, isViewOnly }) => (
  <List
    grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }}
    dataSource={bidders}
    renderItem={(bidder) => {
      const { bidder: bidderName, params } = JSON.parse(bidder.data) || {};
      return (
        <List.Item style={{ marginBottom: 8 }}>
          <Card
            className={styles.bidderCard}
            title={bidderName}
            size="small"
            extra={
              isViewOnly ? null : (
                <Space
                  className={styles.bidderActions}
                  size={0}
                  split={<Divider type="vertical" />}
                >
                  <EditBidder bidder={bidder} />
                  <DeleteBidder bidder={bidder} refetchSpaces={refetchSpaces} />
                </Space>
              )
            }
          >
            {Object.entries(params).map(([param, value], index) => (
              <div key={index}>
                <Typography.Text type="secondary">{param}</Typography.Text>
                <Typography.Text type="primary" style={{ marginLeft: 8 }}>
                  {JSON.stringify(value)}
                </Typography.Text>
              </div>
            ))}
          </Card>
        </List.Item>
      );
    }}
  />
);

const selectedSpacesActions = ({
  selectedRowKeys: selectedSpaceIds,
  clearSelection,
  spacesById,
  refetchSpaces,
}) => {
  const selectedSpaces = selectedSpaceIds.map((id) => spacesById[id]);
  return [
    <CopySelectedSpaces
      key="copy-selected"
      selectedSpaces={selectedSpaces}
      clearSelection={clearSelection}
    />,
    <DeleteSelectedSpaces
      key="deleted-selected"
      selectedSpaceIds={selectedSpaceIds}
      clearSelection={clearSelection}
      refetchSpaces={refetchSpaces}
    />,
  ];
};

const DEFAULT_SPACES_SCOPE = 'production';

const StaticSpacesTable = ({ website, isViewOnly }) => {
  const [spacesScope, setSpacesScope] = useState(DEFAULT_SPACES_SCOPE); // Should only be set to either 'production' or 'staging'
  // Reset scope if navigating from one website's Website Details page to another website's
  useEffect(() => {
    setSpacesScope(DEFAULT_SPACES_SCOPE);
  }, [website.id]);

  // Fetch production or staging Spaces according to the `spacesScope` set.
  // `refetchSpaces` will then reload the Spaces corresponding to the current `spacesScope`.
  const { data: spaces, error: spacesError, mutate: refetchSpaces } = useSWR(
    ['/SpaceList', website.id, spacesScope],
    () => api.listSpaces({ website_id: website.id, staging: spacesScope === 'staging' })
  );

  // Fetch the other scope's Spaces (the opposite of whatever `spacesScope` is set to) so we can identify which Spaces differ between production & staging environments.
  const { data: otherScopeSpaces, error: otherScopeSpacesError } = useSWR(
    ['/SpaceList', website.id, spacesScope === 'staging' ? 'production' : 'staging'],
    () => api.listSpaces({ website_id: website.id, staging: spacesScope !== 'staging' })
  );

  const { data: bidders, error: biddersError } = useSWR(['/BidderList', website.id], () =>
    api.listBidders({ website_id: website.id })
  );

  const biddersById = bidders?.reduce(
    (biddersById, bidder) => ({
      ...biddersById,
      [bidder.id]: bidder,
    }),
    {}
  );

  const spacesById =
    biddersById &&
    spaces?.reduce(
      (spacesById, space) => ({
        ...spacesById,
        [space.id]: {
          ...space,
          bidders: (space.bidder_ids || [])
            .map((bidderId) => biddersById[bidderId])
            .filter((bidder) => bidder && bidder.data) // Ignore 'adx' and 'direct' bidders whose data is `""`
            .sort((bidderA, bidderB) => compareAlphabetically(bidderA?.name, bidderB?.name)),
        },
      }),
      {}
    );

  return (
    <OnceLoaded
      error={spacesError || otherScopeSpacesError || biddersError}
      isLoading={!spaces || !otherScopeSpaces || !bidders}
      render={() => (
        <SearchableEntityTableStatic
          title="Static BT Spaces"
          afterTitle={
            <Space>
              <Radio.Group
                onChange={(event) => {
                  setSpacesScope(event.target.value);
                }}
                size="small"
                value={spacesScope}
              >
                <Radio.Button value="production">Prod</Radio.Button>
                <Radio.Button value="staging">Staging</Radio.Button>
              </Radio.Group>
              {!isSpaceEqual(spaces, otherScopeSpaces, biddersById) && (
                <Tooltip title="Spaces between Prod and Staging are not the same.">
                  <ExclamationCircleOutlined />
                </Tooltip>
              )}
            </Space>
          }
          staticTableData={spaces}
          textSearchFieldNames={['name', 'device_type', 'selector', 'sizes']} // TODO: Add more
          actions={
            isViewOnly ? null : (
              <CodeEditorModal
                key="upload-spaces"
                buttonText="Upload Spaces"
                buttonProps={{ icon: <UploadOutlined /> }}
                modalTitle={`Upload Spaces to ${capitalize(spacesScope)}`}
                okText="Upload"
                editorPlaceholder="Enter Spaces data in Prebid format. Existing spaces will be overwritten!"
                saveCode={(spaces) =>
                  api.uploadSpaces(website.id, spaces, spacesScope === 'staging')
                } // Upload to staging when `spacesScope` is set to 'staging'
                transformBeforeSave={(parsedJsonCode) => {
                  if (typeof parsedJsonCode === 'object' && parsedJsonCode !== null) {
                    const spaces = Array.isArray(parsedJsonCode)
                      ? parsedJsonCode
                      : [parsedJsonCode]; // Allow just a single Space to be provided in the code editor modal to be uploaded
                    return spaces.map(({ code, device_type, selector, bids, sizes, position }) => ({
                      code,
                      device_type,
                      // For each space, if no selector value has been provided, use its `code`
                      selector: { value: selector?.value || `[id="${code}"]` },
                      bids,
                      sizes,
                      position,
                    }));
                  } else {
                    return Promise.reject('Invalid Space data provided!');
                  }
                }}
                onSuccess={({ website_id }) => {
                  // Show success message and trigger spaces & bidders refresh to reflect update
                  message.success('Spaces successfully uploaded!');
                  refetchSpaces();
                  mutate(['/BidderList', website_id]);
                }}
              />
            )
          }
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareAlphabetically(a.name, b.name),
              width: 260,
              render: (name) => (
                <Space>
                  {name}
                  {!isSpaceEqual(spaces, otherScopeSpaces, biddersById, name) ? (
                    <Tooltip
                      title={`This space has not been uploaded, or has a different value in ${
                        spacesScope === 'production' ? 'Staging' : 'Production'
                      }.`}
                    >
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  ) : null}
                </Space>
              ),
            },
            {
              title: `Device Type`,
              dataIndex: 'device_type',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareAlphabetically(a.device_type, b.device_type),
              width: 110,
            },
            {
              title: `Selector`,
              dataIndex: ['selector', 'value'],
              render: (selectorValue) => <Typography.Text code>{selectorValue}</Typography.Text>,
            },
            {
              title: 'Sizes',
              dataIndex: 'sizes',
              render: (sizes) => (
                <Space wrap>
                  {[...sizes].sort(sortSizes).map((size, index) => (
                    <span key={`${index}-${size}`}>{size}</span>
                  ))}
                </Space>
              ),
            },
            {
              title: (
                <span>
                  Position
                  <HelpTooltip title="OpenRTB `pos` value (representing where an ad unit is on the page) sent in Prebid bid requests to SSPs." />
                </span>
              ),
              dataIndex: 'position',
              render: (position) =>
                isNil(position) ? null : <Typography.Text code>{position}</Typography.Text>,
              width: 105,
              align: 'center',
            },
            {
              title: `Bidders`,
              key: 'bidders',
              render: (_, { id }) => spacesById[id].bidders.length,
              width: 70,
              align: 'center',
            },
            {
              // TODO: Remove repetition of this (and other columns)
              title: `Created (${TIME_ZONE})`,
              dataIndex: 'created_at',
              sortDirections: ['descend', 'ascend', 'descend'],
              sorter: (a, b) => compareChronologically(a.created_at, b.created_at),
              render: (dateISOString) => {
                const date = moment(dateISOString);
                return (
                  <Space>
                    <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                    <span>{date.format('(HH:mm)')}</span>
                  </Space>
                );
              },
              width: 200,
            },
            {
              title: 'Enabled',
              dataIndex: 'disabled',
              sortDirections: ['descend', 'ascend', 'descend'],
              sorter: (a, b, currentSortDirection) => {
                const isAEnabled = !a.disabled;
                const isBEnabled = !b.disabled;
                if (isAEnabled === isBEnabled) {
                  // Always sort spaces by name in ascending order when their enabled statuses are the same
                  // (So when this column is in descending order we need to reverse the result of the localeCompare function.)
                  return (
                    (currentSortDirection === 'descend' ? -1 : 1) *
                    compareAlphabetically(a.name, b.name)
                  );
                } else {
                  return isAEnabled && !isBEnabled ? -1 : 1; // Enabled spaces shown first when sorted in ascending order
                }
              },
              render: (disabled, { id }) => (
                <ToggleEnablePopconfirm
                  prompt={`Are you sure you want to ${disabled ? 'enable' : 'disable'} this space?`}
                  isToggled={!disabled}
                  toggleEnable={(newIsEnabled) => api.updateSpace({ id, disabled: !newIsEnabled })}
                  onToggled={({ space }) => {
                    // Show success message and trigger spaces refresh to reflect update
                    message.success(`${space.name} successfully updated!`);
                    refetchSpaces();
                  }}
                  isDisabled={isViewOnly}
                />
              ),
              width: 82,
              align: 'center',
            },
            {
              key: 'actions',
              render: (_, { id }) => (
                <Space size={0} split={<Divider type="vertical" />}>
                  <CopySpace space={spacesById?.[id]} />
                  <EditSpace space={spacesById?.[id]} refetchSpaces={refetchSpaces} />
                  <DeleteSpace spaceId={id} refetchSpaces={refetchSpaces} />
                </Space>
              ),
              width: 77,
              hidden: isViewOnly,
            },
          ].filter((column) => !column.hidden)}
          expandable={{
            rowExpandable: ({ id }) => spacesById?.[id]?.bidders?.length > 0,
            expandedRowRender: ({ id }) => (
              <Bidders
                bidders={spacesById?.[id]?.bidders}
                refetchSpaces={refetchSpaces}
                isViewOnly={isViewOnly}
              />
            ),
          }}
          selectable={
            isViewOnly
              ? false
              : {
                  entityTerm: 'Space',
                  actions: selectedSpacesActions,
                  actionsProps: { spacesById, refetchSpaces },
                }
          }
        />
      )}
    />
  );
};

export default StaticSpacesTable;
