import { Typography } from 'antd';
import { MetricsTooltips } from '../../components';
import MetricsPie from './../Metrics/MetricsPie';

export const RLMetricsPie = ({ title, tooltipText, metrics, metricsLoading, metricsError }) => {
  const metricPieLegendConfig = (screenSizes) => {
    const isSmallScreen = screenSizes.xs && !screenSizes.sm;
    if (screenSizes.xl || screenSizes.lg) {
      return {
        position: 'bottom',
        layout: 'vertical',
      };
    } else if (!isSmallScreen) {
      return {
        position: 'right',
        layout: 'vertical',
      };
    }
    return {
      position: 'bottom',
    };
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <MetricsTooltips
          id={'tooltip_' + title.toLowerCase().replaceAll(' ', '_')}
          isBackfillIncluded={false}
          help={tooltipText}
        />
      </div>
      <MetricsPie
        data={metrics}
        loading={metricsLoading}
        error={metricsError}
        angleField="count"
        colorField="category"
        height={400}
        legend={metricPieLegendConfig}
      />
    </>
  );
};
