import React from 'react';
import { Col, Row } from 'antd';
import { MetricsCard } from '../../../components';
import { isNaN } from 'lodash';

const LostAdRevenueMetricsView = ({ metrics }) => {
  const { pageviews, revenue, rate } = metrics;

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="RL Pageviews"
            value={!isNaN(pageviews.value) ? pageviews.value : 0}
            error={pageviews.error}
            loading={pageviews.loading}
          />
        </Col>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="Lost Ad Revenue"
            value={!isNaN(revenue.value) ? revenue.value : 0}
            valueProps={{ prefix: '$', precision: 2 }}
            error={revenue.error}
            loading={revenue.loading}
            statisticStyle={{ color: 'red' }}
            help="The calculated amount of potential revenue that Readership Link could recover from adblocking visitors. This utilizes blocked pageviews and RPM."
          />
        </Col>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="Adblocking Rate"
            value={!isNaN(rate.value) ? rate.value : 0}
            valueProps={{ suffix: '%', precision: 1 }}
            error={rate.error}
            loading={rate.loading}
            statisticStyle={{ color: 'red' }}
            help="% of pageviews blocking ads"
          />
        </Col>
      </Row>
    </>
  );
};

export default LostAdRevenueMetricsView;
