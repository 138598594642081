import {
  MODE_ADAPTIVE_WALL,
  MODE_HARD_WALL,
  MODE_SOFT_WALL,
} from '../components/SoftMessageWall/FormComponents/WallModeSelector';

export const getSettingsForMode = (mode) => {
  switch (mode) {
    case MODE_SOFT_WALL:
      return {
        hard_message_wall_mode_enabled: false,
        adaptive_hard_message_wall_mode_enabled: false,
      };
    case MODE_HARD_WALL:
      return {
        hard_message_wall_mode_enabled: true,
        adaptive_hard_message_wall_mode_enabled: false,
      };
    case MODE_ADAPTIVE_WALL:
      return {
        hard_message_wall_mode_enabled: false,
        adaptive_hard_message_wall_mode_enabled: true,
      };
    default:
      return {
        // return soft wall mode setting by default
        hard_message_wall_mode_enabled: false,
        adaptive_hard_message_wall_mode_enabled: false,
      };
  }
};
