import { BarChartOutlined } from '@ant-design/icons';
import { Card, Space, Statistic, Tooltip, Typography } from 'antd';
import { useLogger } from '../../services/log';
import { DownloadCSV, HelpTooltip } from '../index';

import styles from './Metrics.module.less';

const BackfillTooltip = () => (
  <Tooltip
    title="This data is affected by the inclusion of backfill revenue."
    overlayStyle={{ maxWidth: 260 }}
  >
    <BarChartOutlined />
  </Tooltip>
);

const MetricsHelpTooltip = ({ id, title }) => {
  const { logMouseOver } = useLogger();

  const onOpenChangeHandler = (opened) => {
    if (!opened) return;
    logMouseOver(id);
  };

  return (
    <HelpTooltip
      className={styles.metricsHelpTooltip}
      title={title}
      overlayStyle={{ maxWidth: 260 }}
      onOpenChange={onOpenChangeHandler}
    />
  );
};

export const MetricsTooltips = ({ id, help, downloadCSV, isBackfillIncluded }) => (
  <Space className={styles.metricsTooltips}>
    {isBackfillIncluded ? <BackfillTooltip /> : null}
    {downloadCSV ? <DownloadCSV {...downloadCSV} /> : null}
    {help ? <MetricsHelpTooltip id={id} title={help} /> : null}
  </Space>
);

export const MetricsCard = ({
  title,
  value,
  valueProps,
  error,
  loading,
  isBackfillIncluded,
  help,
  statisticStyle,
}) => (
  <Card className={styles.metricsCard}>
    <Typography.Title level={5}>{title}</Typography.Title>
    <MetricsTooltips
      id={'tooltip_' + title.toLowerCase().replaceAll(' ', '_')}
      isBackfillIncluded={isBackfillIncluded}
      help={help}
    />
    {/* TODO: Handle error */}
    <Statistic
      className={styles.statistic}
      loading={loading}
      value={value}
      {...valueProps}
      valueStyle={statisticStyle}
    />
  </Card>
);
