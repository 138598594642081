import useSWR from "swr";
import api from "../../../services/api";
import { isNil } from "lodash";

export const useLostAdRevenueMetrics = (org, dateRange, rlEnabled) => {
  const { id: orgId } = org;
  const [dateStartString, dateEndString] = dateRange;

  const swrKeyProps = [orgId, dateStartString, dateEndString];

  const resourceScopedParams = {
    resource_type: 'org',
    id: orgId,
    start_date: dateStartString,
    end_date: dateEndString,
  };

  const { data: aaPageviews, error: aaPageviewsError, isLoading: aaPageviewsLoading } = useSWR(
    rlEnabled ? null : ['/AAPageViewMetrics', ...swrKeyProps],
    () => api.getAAPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const { data: abPageviews, error: abPageviewsError, isLoading: abPageviewsLoading } = useSWR(
    rlEnabled ? null : ['/ABPageViewMetrics', ...swrKeyProps],
    () => api.getABPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const { data: cpm, error: cpmError, isLoading: cpmLoading } = useSWR(
    ['/CPMMetrics', ...swrKeyProps],
    () => api.getCPMMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const cpmValue = !isNil(cpm?.total) ? (cpm.total / cpm?.data?.length).toFixed(2) : 0;

  const isLoading = aaPageviewsLoading || abPageviewsLoading || cpmLoading;
  const error = aaPageviewsError || abPageviewsError || cpmError;

  return {
    aaPageviews: aaPageviews?.total || 0,
    abPageviews: abPageviews?.total || 0,
    cpm: cpmValue,
    loading: isLoading,
    error
  }
}
