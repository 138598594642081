import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useLogger } from '../services/log';

const DownloadCSV = ({ filename, data, headers, tooltip = 'Download CSV', className }) => {
  const { logDownload } = useLogger();
  const onClickHandler = () => {
    logDownload(filename, { headers });
  }
  return data ? (
    <Tooltip title={tooltip} className={className}>
      <CSVLink filename={filename} data={data} headers={headers} target="_blank" onClick={onClickHandler}>
        <DownloadOutlined />
      </CSVLink>
    </Tooltip>
  ) : null;
};

export default DownloadCSV;
