import { ClockCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  message,
  Space,
  Timeline,
  Typography,
  Tabs,
} from 'antd';
import { round } from 'lodash';
import moment from 'moment';
import React from 'react';
import useSWR, { mutate } from 'swr';
import api from '../../services/api';
import { DeleteEntityPopconfirm, NoSaveModal, SaveEntityModal } from '../../services/handlers';
import { useAsync } from '../../services/hooks';

const { Text } = Typography;

const CPM_DECIMALS_TO_DISPLAY = 4;

const percentageFormatter = (value) => `${value}%`;
const percentageParser = (value) => value.replace('%', '');
const dollarFormatter = (value) => `$ ${value}`;
const dollarParser = (value) => value.replace(/\$\s?/g, '');

function findCurrentAndNextRevShares(revShares = []) {
  let currentRevShare;
  let nextRevShare;

  revShares.forEach((revShare) => {
    const activationMoment = moment.utc(revShare.activated_at);
    if (activationMoment.isBefore()) {
      // Find most recent rev share that was activated before today - set it as currentRevShare
      if (!currentRevShare || activationMoment.isAfter(currentRevShare.activated_at)) {
        currentRevShare = revShare;
      }
    } else if (activationMoment.isSameOrAfter()) {
      // Find next upcoming rev share that will be activated starting or after today - set it as nextRevShare
      if (!nextRevShare || activationMoment.isBefore(nextRevShare.activated_at)) {
        nextRevShare = revShare;
      }
    }
  });

  return { currentRevShare, nextRevShare };
}

function isRevShareZero(revShare) {
  if (revShare) {
    const cpmSum = revShare.ad_serving_cpm + revShare.direct_cpm + revShare.house_cpm;
    return revShare.programmatic_percent === 0 && cpmSum === 0 && revShare.bt_demand_percent === 0;
  }
}

function formatRevShare(revShare) {
  if (revShare) {
    const cpmSum = revShare.ad_serving_cpm + revShare.direct_cpm + revShare.house_cpm;
    const cpmString = cpmSum ? ` + $${cpmSum.toFixed(CPM_DECIMALS_TO_DISPLAY)} CPM` : '';

    const hasBTDemand = revShare.bt_demand_percent || revShare.bt_demand_ad_serving_cpm;
    const BTDemandString = hasBTDemand ? ' + BT Demand' : '';

    return `${round(revShare.programmatic_percent * 100, 2)}%${cpmString}${BTDemandString}`;
  } else {
    return '';
  }
}

/**
 * Transforms the data from `revShare` into values that the form component expects/can handle.
 * Can also be used to set initial form values if no `revShare` is passed in.
 * @param {Object} revShare data returned by the API for an existing rev share being edited in the rev share form.
 */
function transformToFormValues({
  activated_at,
  programmatic_percent = 0,
  direct_cpm = 0,
  house_cpm = 0,
  ad_serving_cpm = 0,
  bt_demand_ad_serving_cpm = 0,
  bt_demand_percent = 0,

  rl_programmatic_percent = 0,
  rl_direct_cpm = 0,
  rl_house_cpm = 0,
} = {}) {
  return {
    // Transform ISO datetime string into moment date (time returned from API should always be T00:00:00Z)
    activated_at: activated_at ? moment.utc(activated_at) : null,

    // Transform these percent values (which are set as a decimal representation (i.e. a number between 0 and 1) in the API) into percentage numbers between 0 and 100
    programmatic_percent: round(programmatic_percent * 100, 2),
    bt_demand_percent: round(bt_demand_percent * 100, 2),

    direct_cpm,
    house_cpm,
    ad_serving_cpm,
    bt_demand_ad_serving_cpm,

    rl_programmatic_percent: round(rl_programmatic_percent * 100, 2),
    rl_direct_cpm,
    rl_house_cpm,
  };
}
/**
 * Transforms the data from the form component into `revShare` values that the create/edit API call expects/can handle.
 * @param {Object} formValues data returned by the rev share form for a new or existing (being edited) rev share about to be saved through the API.
 */
function transformFromFormValues({
  activated_at,
  programmatic_percent,
  direct_cpm,
  house_cpm,
  ad_serving_cpm,
  bt_demand_ad_serving_cpm,
  bt_demand_percent,
  rl_programmatic_percent,
  rl_direct_cpm,
  rl_house_cpm,
}) {
  return {
    // Transform moment date into ISO datetime string (and set its time to T00:00:00Z)
    activated_at: activated_at.startOf('day').toISOString(),

    // Transform these percent values (which are set as a percentage number between 0 and 100 in the form fields) into their decimal representation (i.e. a number between 0 and 1)
    programmatic_percent: round(programmatic_percent / 100, 4),
    bt_demand_percent: round(bt_demand_percent / 100, 4),

    direct_cpm,
    house_cpm,
    ad_serving_cpm,
    bt_demand_ad_serving_cpm,

    rl_programmatic_percent: round(rl_programmatic_percent / 100, 4),
    rl_direct_cpm,
    rl_house_cpm,
  };
}

const AddRevShare = ({ orgId }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button onClick={openModal} type="dashed" icon={<PlusOutlined />} block>
        Set Upcoming Rev Share
      </Button>
    )}
    modalTitle="Set Upcoming Rev Share"
    // Add org id, and transform form values before api request
    transformBeforeSave={(values) => ({
      org_id: orgId,
      ...transformFromFormValues(values),
    })}
    saveEntity={api.createRevShare}
    onSuccess={() => {
      // Show success message, trigger rev share refresh to reflect new addition, and hide form
      message.success(`Rev share settings successfully created!`);
      mutate(['/RevShareList', orgId]);
    }}
    formComponent={RevShareFormContainer}
    formInitialValues={transformToFormValues()}
    width={650}
  />
);
const EditRevShare = ({ revShare }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button type="link" size="small" onClick={openModal}>
        <EditOutlined />
      </Button>
    )}
    modalTitle="Edit Rev Share"
    // Add id, and transform form values before api request
    transformBeforeSave={(values) => ({
      id: revShare.id,
      ...transformFromFormValues(values),
    })}
    saveEntity={api.updateRevShare}
    onSuccess={({ rev_share: revShare }) => {
      // Show success message, trigger rev share refresh to reflect update, and hide form
      message.success(`Rev share settings successfully updated!`);
      mutate(['/RevShareList', revShare.org_id]);
    }}
    formComponent={RevShareFormContainer}
    formInitialValues={transformToFormValues(revShare)}
    width={650}
  />
);
const DeleteRevShare = ({ revShare, isDisabled }) => {
  const { execute: deleteRevShare } = useAsync(() => api.deleteRevShare(revShare.id));

  return (
    <DeleteEntityPopconfirm
      isDisabled={isDisabled}
      deleteEntity={deleteRevShare}
      onDeleted={() => {
        message.success('Future rev share settings successfully deleted!');
        mutate(['/RevShareList', revShare.org_id]); // Trigger rev share refresh to reflect deletion
      }}
    />
  );
};

const RevShareFormMain = () => (
  <>
    <Form.Item
      name="programmatic_percent"
      label="Programmatic"
      tooltip="The % of a publisher's programmatic revenue BT gets"
      rules={[{ required: true, type: 'number', message: 'Please input a valid percentage!' }]}
    >
      <InputNumber min={0} max={100} formatter={percentageFormatter} parser={percentageParser} />
    </Form.Item>

    {/* CPM fields */}
    <Form.Item name="direct_cpm" label="Direct CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
    <Form.Item name="house_cpm" label="House CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
    <Form.Item name="ad_serving_cpm" label="Ad Serving CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>

    {/* BT Demand fields */}
    <Form.Item
      name="bt_demand_percent"
      label="BT Demand"
      tooltip="The % of a publisher's BT Demand revenue BT gets"
      rules={[{ required: true, type: 'number', message: 'Please input a valid percentage!' }]}
    >
      <InputNumber min={0} max={100} formatter={percentageFormatter} parser={percentageParser} />
    </Form.Item>
    <Form.Item name="bt_demand_ad_serving_cpm" label="BT Demand Ad Serving CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
  </>
);

const RevShareFormRL = () => (
  <>
    <Form.Item
      name="rl_programmatic_percent"
      label="Programmatic"
      tooltip="The % of a publisher's programmatic revenue BT gets"
      rules={[{ required: true, type: 'number', message: 'Please input a valid percentage!' }]}
    >
      <InputNumber min={0} max={100} formatter={percentageFormatter} parser={percentageParser} />
    </Form.Item>

    {/* CPM fields */}
    <Form.Item name="rl_direct_cpm" label="Direct CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
    <Form.Item name="rl_house_cpm" label="House CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
  </>
);

const RevShareFormContainer = ({ formInstance, saveError }) => (
  <Form
    name="rev-share"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      name="activated_at"
      label="Starting Date"
      rules={[{ required: true, message: 'Please input a starting date!' }]}
    >
      <DatePicker
        disabledDate={(date) => date && date < moment().add(1, 'days').startOf('day')} // Disable all days before tomorrow
        showToday={false} // We hide the "Today" button because it will always be disabled
      />
    </Form.Item>
    <Tabs
      defaultActiveKey="main"
      items={[
        {
          key: 'main',
          label: 'Main',
          children: <RevShareFormMain />,
        },
        {
          key: 'rl',
          label: 'Readership Link',
          children: <RevShareFormRL />,
        },
      ]}
    />
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const RevShares = ({ orgId, isViewOnly }) => {
  const { data: revShares, error: revSharesError } = useSWR(['/RevShareList', orgId], () =>
    api.listRevShares({ org_id: orgId })
  );

  const { currentRevShare, nextRevShare } = findCurrentAndNextRevShares(revShares);

  return revSharesError ? (
    `Error retrieving org's revenue share`
  ) : revShares && currentRevShare ? (
    <NoSaveModal
      width={820}
      triggerRender={({ openModal }) => {
        const currRevShareIsEmpty = isRevShareZero(currentRevShare);
        const currentRevShareString = formatRevShare(currentRevShare);
        const nextRevShareString = nextRevShare
          ? ` (until ${moment.utc(nextRevShare.activated_at).format('MMM D, YYYY')})`
          : '';

        return (
          <Button
            type="link"
            size="small"
            danger={currRevShareIsEmpty}
            style={{ fontWeight: currRevShareIsEmpty ? 'bold' : 'normal' }}
            onClick={openModal}
          >
            {`${currentRevShareString}${nextRevShareString}`}
          </Button>
        );
      }}
      modalTitle="Blockthrough Revenue Share"
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {isViewOnly ? null : <AddRevShare orgId={orgId} />}
        <Timeline mode="left">
          {revShares.map((revShare) => {
            const {
              id,
              activated_at,
              programmatic_percent,
              ad_serving_cpm,
              direct_cpm,
              house_cpm,
              bt_demand_ad_serving_cpm,
              bt_demand_percent,
            } = revShare;
            const cpmSum = ad_serving_cpm + direct_cpm + house_cpm;

            const isCurrentRevShare = currentRevShare.activated_at === activated_at;
            const isAnUpcomingRevShare = moment
              .utc(activated_at)
              .isAfter(currentRevShare.activated_at);
            return (
              <Timeline.Item
                key={id}
                color={isCurrentRevShare ? 'blue' : 'gray'}
                {...(isAnUpcomingRevShare
                  ? {
                      dot: (
                        <ClockCircleOutlined
                          style={{ fontSize: 17, position: 'relative', top: -1 }}
                        />
                      ),
                    }
                  : {})}
              >
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  <div>
                    <div>
                      <Text underline>{moment.utc(activated_at).format('MMM D, YYYY')}</Text>
                    </div>
                    <div>
                      <Space>
                        <Space style={{ minWidth: 160 }}>
                          <Text disabled={!programmatic_percent}>Programmatic</Text>
                          <Text strong disabled={!programmatic_percent}>
                            {round(programmatic_percent * 100, 2)}%
                          </Text>
                        </Space>
                        <Space>
                          <Text disabled={!cpmSum}>CPM</Text>
                          <Space>
                            <Text strong disabled={!cpmSum}>
                              ${cpmSum.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                            </Text>
                            <Text italic disabled={!cpmSum}>
                              (
                            </Text>
                            <Space size={6}>
                              <Space size={6}>
                                <Text italic disabled={!direct_cpm}>
                                  Direct
                                </Text>
                                <Text italic strong disabled={!direct_cpm}>
                                  ${direct_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                                </Text>
                              </Space>
                              <Text italic disabled={!cpmSum}>
                                +
                              </Text>
                              <Space size={6}>
                                <Text italic disabled={!house_cpm}>
                                  House
                                </Text>
                                <Text italic strong disabled={!house_cpm}>
                                  ${house_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                                </Text>
                              </Space>
                              <Text italic disabled={!cpmSum}>
                                +
                              </Text>
                              <Space size={6}>
                                <Text italic disabled={!ad_serving_cpm}>
                                  Ad Serving
                                </Text>
                                <Text italic strong disabled={!ad_serving_cpm}>
                                  ${ad_serving_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                                </Text>
                              </Space>
                            </Space>
                            <Text italic disabled={!cpmSum}>
                              )
                            </Text>
                          </Space>
                        </Space>
                      </Space>
                    </div>
                    <div>
                      <Space>
                        <Space style={{ minWidth: 160 }}>
                          <Text disabled={!bt_demand_percent}>BT Demand</Text>
                          <Text strong disabled={!bt_demand_percent}>
                            {round(bt_demand_percent * 100, 2)}%
                          </Text>
                        </Space>
                        <Space>
                          <Text disabled={!bt_demand_ad_serving_cpm}>BT Demand Ad Serving CPM</Text>
                          <Text strong disabled={!bt_demand_ad_serving_cpm}>
                            ${bt_demand_ad_serving_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                          </Text>
                        </Space>
                      </Space>
                    </div>
                  </div>
                  <Space size={0} split={<Divider type="vertical" />} style={{ minWidth: 45 }}>
                    {!isViewOnly && isAnUpcomingRevShare
                      ? [
                          <EditRevShare key="edit-rev-share" revShare={revShare} />,
                          <DeleteRevShare key="delete-rev-share" revShare={revShare} />,
                        ]
                      : ''}
                  </Space>
                </Space>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Space>
    </NoSaveModal>
  ) : null;
};

export default RevShares;
