import React from 'react';
import LostAdRevenueMetricsView from './LostAdRevenueMetricsView';
import { useLostAdRevenueMetrics } from './useLostAdRevenueMetrics';

const LostAdRevenueMetricsContainer = ({ org, dateRange, rlEnabled, performanceMetrics }) => {
  const { loading, error, aaPageviews, abPageviews, cpm } = useLostAdRevenueMetrics(
    org,
    dateRange,
    rlEnabled
  );

  const metrics = performanceMetrics?.reduce(
    (acc, cur) => {
      acc.aaPageviews += Number(cur.aa_page_views);
      acc.abPageviews += Number(cur.ab_page_views);
      return acc;
    },
    { aaPageviews: 0, abPageviews: 0 }
  );

  const [aaViews, abViews] = [
    rlEnabled ? metrics?.aaPageviews : aaPageviews,
    rlEnabled ? metrics.abPageviews : abPageviews,
  ];

  const pageviews = {
    value: aaViews + abViews,
    loading,
    error,
  };

  return (
    <LostAdRevenueMetricsView
      metrics={{
        pageviews,
        revenue: {
          value: (abViews * cpm) / 1000,
          error,
          loading,
        },
        rate: {
          value: (abViews / pageviews?.value) * 100,
          error,
          loading,
        },
      }}
    />
  );
};

export default LostAdRevenueMetricsContainer;
