import { Form, Select } from 'antd';
import CustomCTAOptions from './CustomCTAOptions';
import { useEffect } from 'react';
import { MODE_SOFT_WALL } from './WallModeSelector';

export const MODE_FOOTER = 'footer';
export const MODE_MODAL = 'modal';
export const MODE_CUSTOM_CTA = 'custom-cta-modal';

const defaultView = MODE_MODAL;

const ModeFormItem = ({ env, form, disabled }) => {
  const viewModeFieldName = [env, 'am_settings', 'view'];
  const viewModeValue = Form.useWatch(viewModeFieldName, form);
  const wallModeFieldName = [env, 'am_settings', 'wall_mode'];

  // const isHardWallEnabled = Form.useWatch(hardWallModeFieldName, form);
  const viewMode = Form.useWatch(viewModeFieldName, form);

  useEffect(() => {
    // set default if value is empty
    if (viewMode === '') {
      form.setFieldValue(viewModeFieldName, defaultView);
    }
  }, [form, env, viewMode]);

  const onViewModeChanged = (mode) => {
    if (mode === MODE_FOOTER) {
      // Ensure Hard Wall Mode is disabled if user sets the Style (View Mode) to 'Footer'
      form.setFieldValue(wallModeFieldName, MODE_SOFT_WALL);
    }
  };

  return (
    <>
      <Form.Item
        label="Style"
        name={viewModeFieldName}
        tooltip="When Hard Mode is enabled, view mode can only be set to Modal."
      >
        <Select
          defaultValue={defaultView}
          disabled={disabled}
          onChange={onViewModeChanged}
          value={viewModeValue}
          options={[
            {
              label: 'Modal',
              value: MODE_MODAL,
            },
            {
              label: 'Footer',
              value: MODE_FOOTER,
            },
            {
              label: 'Custom CTA Modal',
              value: MODE_CUSTOM_CTA,
            },
          ]}
        />
      </Form.Item>
      {viewMode === MODE_CUSTOM_CTA ? <CustomCTAOptions env={env} form={form} /> : null}
    </>
  );
};

export default ModeFormItem;
