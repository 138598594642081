import { Button } from 'antd';
import { getSettingsForMode } from '../../../utils/wallModeUtils';

const MessageWallPreview = ({ formInstance, env, orgId }) => {
  const previewHandler = () => {
    const fieldsValue = formInstance.getFieldsValue()[env]['am_settings'];
    const wallModeSettings = getSettingsForMode(fieldsValue['wall_mode']);

    // set wall mode settings for preview
    fieldsValue['hard_message_wall_mode_enabled'] = wallModeSettings.hard_message_wall_mode_enabled;
    fieldsValue['adaptive_hard_message_wall_mode_enabled'] =
      wallModeSettings.adaptive_hard_message_wall_mode_enabled;

    // prevent MW disabling
    fieldsValue['country_code_mode'] = 'disabled';
    fieldsValue['allowlisted_urls'] = [];
    fieldsValue['render_interval_days'] = 0;
    fieldsValue['page_views_to_render'] = 0;

    // default settings
    // the back-end expect 'render_to_aa_users_disabled', but script itself working with 'allow_render_to_aa_users'
    // that field should include reverse value of 'render_to_aa_users_disabled';
    const renderDisabled = fieldsValue['render_to_aa_users_disabled'];
    fieldsValue['allow_render_to_aa_users'] = !renderDisabled;

    const sParam = window.btoa(encodeURIComponent(JSON.stringify(fieldsValue)));
    const myUrlWithParams = new URL(`${window.location.origin}/message-wall.html`);
    myUrlWithParams.searchParams.append('s', sParam);
    myUrlWithParams.searchParams.append('o', orgId);
    myUrlWithParams.searchParams.append('preview', 'true');

    window.open(myUrlWithParams, '_blank').focus();
  };

  return (
    <Button type="default" onClick={previewHandler}>
      Preview
    </Button>
  );
};

export default MessageWallPreview;
